<template>
  <div class="recode_body">
    <div class="modularBox">
    <el-form :inline="true" class="searchBox" :model="dataForm" @keyup.enter.native="getDataList()" size="small"  label-width="120px">
      <el-form-item label="排班方式：">
        <div class="">
            <el-radio v-model="scheMethod" label="1" @change="searchFun">智慧排班</el-radio>
            <el-radio v-model="scheMethod" label="2" @change="searchFun">手动排班</el-radio>
        </div>
      </el-form-item>
      <el-form-item label="智能条件：">
        <div class="checkArea">
            <el-checkbox-group v-model="condition" @change="searchFun">
              <el-checkbox :label="item.label" :value="item.value" v-for="(item, index) in conditionList" :key="index"></el-checkbox>
            </el-checkbox-group>
        </div>
      </el-form-item>
      <el-form-item class="footer">
        <el-button @click="getDataList()" type="primary">排版预览</el-button>
        <el-button @click="reset()">清 空</el-button>
        <el-button @click="copyDayBefore()" type="primary">同上一天</el-button>
      </el-form-item>
    </el-form>
    </div>
    <div class="tableBox">
      <el-table :data="dataList" border size="small" v-loading="dataListLoading" :fit="true" @selection-change="selectionChangeHandle" style="width: 100%;">
        <af-table-column :afGetData="dataList" prop="taskInfo.taskNo"  label="任务编号">
        </af-table-column>
        <af-table-column  :afGetData="dataList" prop="taskInfo.taskTime" label="任务日期">
        </af-table-column>
        <af-table-column  :afGetData="dataList" prop="taskInfo.takeGoodsTime" label="提货时间">
        </af-table-column>
        <af-table-column  :afGetData="dataList" prop="taskInfo.customerName" label="客户名称">
        </af-table-column>
        <af-table-column  :afGetData="dataList" prop="taskInfo.vehicleType" :formatter = "dictConvert" label="所需车型">
        </af-table-column>
        <af-table-column  :afGetData="dataList" prop="taskInfo.takeGoodsAddressName" label="提货地址名称">
        </af-table-column>
        <af-table-column  :afGetData="dataList" prop="taskInfo.giveGoodsAddressName" label="卸货地址名称">
        </af-table-column>
        <af-table-column prop="dispatchTime" label="排班时间">
        </af-table-column>
        <!--<af-table-column
          prop="dispatchType"
          header-align="center"
          align="center"
          label="排班类型">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.dispatchType === 2" size="small" type="success">车队</el-tag>
            <el-tag v-else type="danger" size="small">指派</el-tag>
          </template>
        </af-table-column>
        <af-table-column
          prop="motorcadeName"
          header-align="center"
          align="center"
          label="车队名称">
        </af-table-column>-->
        <af-table-column prop="vehicleType" :formatter = "dictConvert" label="车型">
        </af-table-column>
        <af-table-column prop="driverName" label="驾驶员">
        </af-table-column>
        <af-table-column prop="vehiclePlate" label="车牌号">
        </af-table-column>
        <af-table-column prop="dispatchStatus" label="任务状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.taskInfo.taskStatus === 0" size="small" type="danger">未提交</el-tag>
            <el-tag v-else-if="scope.row.taskInfo.taskStatus === 1" size="small" type="danger">未排班</el-tag>
            <el-tag v-else-if="scope.row.taskInfo.taskStatus === 2" size="small" type="danger">已排班，未出车</el-tag>
            <el-tag v-else-if="scope.row.taskInfo.taskStatus === 3" size="small" type="danger">已出车，未到达</el-tag>
            <el-tag v-else-if="scope.row.taskInfo.taskStatus === 4" size="small" type="danger">已到达，装货中</el-tag>
            <el-tag v-else-if="scope.row.taskInfo.taskStatus === 5" size="small" type="danger">已装货，配送中</el-tag>
            <el-tag v-else-if="scope.row.taskInfo.taskStatus === 6" size="small" type="success">正常完成</el-tag>
            <el-tag v-else-if="scope.row.taskInfo.taskStatus === 7" size="small" type="warning">异常完成</el-tag>
            <el-tag v-else type="info" size="small">其他</el-tag>
          </template>
        </af-table-column>
        <af-table-column fixed="right" width="120" label="操作">
          <template slot-scope="scope">
            <el-button v-if="isAuth('sys:user:delete')" type="text" size="small" @click="detail(scope.row.taskInfo.id)">详情</el-button>
            <el-button v-if="isAuth('sys:user:update')" type="text" size="small" @click="dispatch(scope.row.id)">修改状态</el-button>
          </template>
        </af-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dataForm: {
        taskInfo: {},
        vehicleType: null,
        driverName: null,
        vehiclePlate: null,
        taskTime: null
      },
      scheMethod: '1', // 排班方式
      condition: [], // 智能条件
      conditionList: [{
        label: '去掉运单未完成',
        value: '1'
      }, {
        label: '去掉不带尾板',
        value: '2'
      }, {
        label: '不要新能源车',
        value: '3'
      }, {
        label: '不要面包车',
        value: '4'
      }, {
        label: '不要4米2',
        value: '5'
      }, {
        label: '不要6米8',
        value: '6'
      }, {
        label: '不要7米6',
        value: '7'
      }, {
        label: '不要6米6',
        value: '8'
      }, {
        label: '去掉评分低车辆',
        value: '9'
      }, {
        label: '去掉维修保养中',
        value: '10'
      }], // 智能条件复选条件
      dataList: [],
      pageIndex: 1,
      pageSize: 20,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      dispatchVisible: false,
      detailVisible: false
    }
  },
  components: {
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    }
  },
  activated () {
    // this.getDataList()
  },
  methods: {
    // 排班方式, 智能条件查询
    searchFun () {
      var values = this.condition
      var checkedList = []
      this.conditionList.find(function (item) {
        for (let i = 0; i < values.length; i++) {
          if (item.label == values[i]) {
            checkedList.push(item)
          }
        }
      })
    },
    // 清空搜索条件
    reset () {
      this.condition = []
      this.scheMethod = '1'
    },
    // 同上一天
    copyDayBefore () {
      // console.log('同上一天')
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      let taskTime = this.dataForm.taskTime
      let taskInfo = this.dataForm.taskInfo
      if (taskTime && taskTime.length === 2) {
        taskInfo.beginTaskTime = taskTime[0]
        taskInfo.endTaskTime = taskTime[1]
      } else {
        taskInfo.beginTaskTime = null
        taskInfo.endTaskTime = null
      }
      this.$http({
        url: this.$http.adornUrl('/dispatch/dispatchRecordList'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'taskInfo.customerName': taskInfo.customerName,
          'taskInfo.taskStatus': taskInfo.taskStatus,
          'taskInfo.beginTaskTime': taskInfo.beginTaskTime,
          'taskInfo.endTaskTime': taskInfo.endTaskTime,
          'dispatchLevel': 2,
          'vehiclePlate': this.dataForm.vehiclePlate,
          'driverName': this.dataForm.driverName,
          'vehicleType': this.dataForm.vehicleType
        })
      }).then(({data}) => {
        if (data) {
          this.dataList = data.list
          this.totalPage = data.total
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
    },
    // 修改
    addOrUpdateHandle (id) {
      // this.addOrUpdateVisible = true
      // this.$nextTick(() => {
      //   this.$refs.addOrUpdate.init(id)
      // })
    },
    // 详情
    detail (id) {
      // this.detailVisible = true
      // this.$nextTick(() => {
      //   this.$refs.detail.init(id)
      // })
    },
    dispatch (id) {
      // let ids = id ? [id] : this.dataListSelections.map(item => {
      //   return item.id
      // })
      // this.dispatchVisible = true
      // this.$nextTick(() => {
      //   this.$refs.dispatch.init(ids)
      // })
    }
  }
}
</script>
<style lang="less">
.recode_body {
  .modularBox {
    // box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px, rgba(0, 0, 0, 0.12) 0px 0px 6px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 3px, rgba(0, 0, 0, 0.08) 0px 0px 3px;
    margin-bottom: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    .searchBox {
      .el-form-item {
        width: 100%;
        margin-bottom: 0;
        .checkArea {
          width: 800px;
          .el-checkbox+.el-checkbox {
            margin-left: 0
          }
        }
      }
      .footer {
        text-align: right;
        padding-right: 50px;
      }
    }
  }
}
</style>

// <style lang="less">
// .el-table th>.cell {
//   white-space: nowrap;
//   text-overflow : clip
// }
// </style>
